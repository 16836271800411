import React, { useState, useEffect } from 'react';
import useJobs from '../../hooks/useJobs';
import usePagination from '../../hooks/usePagination';
import { fetchJobs } from '../../utils/fetch';
import JobInput from './JobInput';
import JobList from './JobList';

const transformJobs = (jobs = []) => {
  return jobs.map((j) => ({
    id: j.id,
    title: j.title,
    department: j.team,
    // location: j?.allows_remote ? 'Remote in India' : j.location.city, // To display remote positions
    location: j.location.city,
    positionType: j.position_type,
    isRemote: j.allows_remote,
    country: j.location.country,
    hostedUrl: j.hosted_url,
    searchText: `${j.title}${j.department}${JSON.stringify(j.location)}${
      j.allows_remote ? 'remote' : ''
    }`,
  }));
};

function JobSearch() {
  const [fetchedJobs, setFetchedJobs] = useState([]);
  const [loading, setLoading] = useState();
  const { jobs, ...inputProps } = useJobs(fetchedJobs);
  const pagination = usePagination(jobs);
  useEffect(() => {
    setLoading(true);
    fetchJobs()
      .then((data) => {
        setFetchedJobs(transformJobs(data?.objects ?? []));
      })
      .catch(() => setFetchedJobs([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <JobInput {...inputProps} disabled={loading} />
      <JobList className="mt-[36px]" data={pagination.data} loading={loading} />
      {pagination.hasMore && (
        <div className="text-center">
          <button
            className="button button-green bg-cf-green w-full md:w-auto mt-8 md:mt-10 hover:brightness-110 active:brightness-110 focus:brightness-110"
            type="button"
            disabled={loading}
            onClick={pagination.loadMore}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default JobSearch;
